<template>
    <div class="container login pt-3">

		<div class="logo">
			<img src="@/assets/logo.png" alt="logo">
		</div>

		<form @submit.prevent="login" class="d-flex flex-column mt-5">
			<input type="text" class="input-field mb-4" name="email" v-model="formData.email" placeholder="Email">

			<input type="password" class="input-field mb-5" name="password" v-model="formData.password" placeholder="Password">

			<button type="submit" class="button mb-4 mt-2">LOGIN</button>
		</form>

		<p class="text-center">Belum ada akun? <router-link to="/register">Register</router-link></p>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
			formData: {
				email: '',
				password: ''
			}
		}
	},
	methods: {
		login() {
			axios.post(`auth/login`, this.formData)
				.then(res => {
					localStorage.setItem('token', res.data.token);
					localStorage.setItem('role', res.data.role);

					this.$router.push('/');
				})
				.catch(err => {
					alert(err.response.data.message);
				}); 
		}
	},
}
</script>

<style scoped>

.container {
	width: 90%;
}

.logo {
	margin: 50px 0;
	display: flex;
	justify-content: center;
}

.logo img {
	width: 70%;
}

.login p a {
	color: black;
	font-weight: 500;
}

button {
	color: white;
}

</style>